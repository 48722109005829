import React from 'react';
import './index.css'

const Footer = () => {
    return ( <
        div >
        <
        div className = 'footer-container' >
        <
        h2 className = 'footer' > Contact < /h2> <
        h4 className = 'footer-content' > Bellamkonda Sathish: 9505161183 < /h4> <
        h4 className = 'footer-content' > Bellamkonda Srinu: 9989132251 < /h4> <
        h3 className = 'footer-content' > Mogaltur, Andhra Pradesh, India, 534281 < /h3> <
        p className = 'footer-content' > @Allcopyrights reserved to Aishwarya Transports < /p> <
        /div> <
        /div>
    );
}

export default Footer;