import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Booking from './components/Booking';

const App = () => {
    return ( <
        Router >
        <
        Routes >
        <
        Route exact path = '/'
        Component = { Home }
        /> <
        Route exact path = '/booking'
        Component = { Booking }
        /> < /
        Routes > <
        /Router>
    );
};

export default App;